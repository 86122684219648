<template>
    <div class="search-container" >
      <input
        type="text"
        v-model="searchQuery"
        @input="searchUsers"
        placeholder="Rechercher un utilisateur..."
        class="search-input"
      />
      <font-awesome-icon icon="search" class="search-icon" />
      <ul v-if="filteredUsers.length > 0" class="user-results">
        <li v-for="user in filteredUsers" :key="user.id" @click="selectUser(user)">
          {{ user.get("firstname") + " " + user.get("lastname") }}
        </li>
      </ul>
    </div>
  </template>
  
  <script>
  import { ref, computed } from 'vue';
  
  export default {
    props: {
      users: {
        type: Array,
        required: true
      }
    },
    setup(props, { emit }) {
      const searchQuery = ref('');

  
      const filteredUsers = computed(() => {
        if (searchQuery.value === '') return [];
        return props.users.filter(user => {
          const fullName = `${user.get("firstname")} ${user.get("lastname")}`.toLowerCase();
          return fullName.includes(searchQuery.value.toLowerCase());
        });
      });
  
      const searchUsers = () => {
        // La recherche est déjà en temps réel grâce au computed property
      };
  
      const selectUser = (user) => {
        emit('select-user', user);
        searchQuery.value = '';
        shrinkSearchBar();
      };
  
      return {
        searchQuery,
        filteredUsers,
        searchUsers,
        selectUser
      };
    }
  }
  </script>
  
  <style scoped>
  .search-container {
    position: relative;
    width: 100%;
    max-width: 400px; /* Permet de limiter la largeur */
    margin: 0 auto; /* Centre la barre de recherche */
    transition: width 0.3s ease;
  }
  
  .search-container.expanded {
    width: 100%; /* Prend toute la largeur disponible */
  }
  
  .search-input {
    width: 100%;
    padding: 10px 40px 10px 15px;
    border: 1px solid #ccc;
    border-radius: 20px;
    font-size: 16px;
    outline: none;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
  }
  
  .search-input:focus {
    border-color: #239380;
    box-shadow: 0 4px 8px#239380;
  }
  
  .search-icon {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    color: #666;
    cursor: pointer;
  }
  
  .user-results {
    position: absolute;
    top: calc(100% + 5px);
    left: 0;
    right: 0;
    background-color: white;
    border: 1px solid #ccc;
    border-top: none;
    border-radius: 0 0 8px 8px;
    list-style-type: none;
    padding: 0;
    margin: 0;
    max-height: 250px;
    overflow-y: auto;
    z-index: 1000;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .user-results li {
    display: flex;
    align-items: center;
    padding: 12px;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .user-results li img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .user-results li .user-info {
    display: flex;
    flex-direction: column;
  }
  
  .user-results li:hover {
    background-color: #f8f9fa;
  }
  
  .user-results li.selected {
    background-color: #239380;
    color: white;
  }
  
  .user-results li.selected .user-info {
    color: white;
  }
  
  @media (max-width: 600px) {
    .search-container {
      max-width: 100%;
    }
  
    .search-input {
      font-size: 14px;
      padding: 8px 35px 8px 10px;
    }
  
    .user-results li {
      padding: 10px;
    }
  
    .user-results li img {
      width: 25px;
      height: 25px;
    }
  }

  /* Personnalisation de la scrollbar */
.user-results {
  scrollbar-width: thin;
  scrollbar-color: #239380 #f0f0f0;
}

/* Pour Chrome, Edge et Safari */
.user-results::-webkit-scrollbar {
  width: 8px;
}

.user-results::-webkit-scrollbar-thumb {
  background-color: #239380;
  border-radius: 10px;
  border: 2px solid #f0f0f0; /* Crée un effet d'espace entre la scrollbar et son conteneur */
}

.user-results::-webkit-scrollbar-track {
  background-color: #f0f0f0;
  border-radius: 10px;
}

  </style>
  