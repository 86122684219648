<template>
  <div ref="element">
    <div>
      <div>
        <transition name="slide-fade">
          <div class="notification-container" v-if="showNotification">
            <div class="notification-box">
              <button @click="closeNotification" class="notification-close">
                &times;
              </button>
              <div class="notification-text">Exercice terminé</div>
            </div>
          </div>
        </transition>
      </div>
      <!-- <div
          v-html="descriptionPro"
          class="modules-list dir-col-mobile descriptionPro"
          v-if="descriptionPro"
        ></div> -->
      <!-- <bfpComponent v-if="bfp_acces" /> -->
      <!-- BOUTON AFFICHAGE  -->
      <!-- <div class="buttonprog">
          <button
            class="buttonChoix"
            v-on:click="show_theme()"
            v-bind:class="{ activeRessources: isTheme }"
          >
            Afficher par thèmes
          </button>
          <button
            v-if="user.therapeute && idPatientTherapeute === null"
            class="buttonChoix"
            v-on:click="show_all()"
            v-bind:class="{ activeRessources: isAll }"
          >
            Afficher tout
          </button>
        </div> -->
      <template v-for="theme in themes">
        <div v-show="isTheme">
          <div v-if="theme.attributes.ordre === 7">
            <CarouselFolders
              @TerminerExerciceQCM="handleTerminerExerciceQCM"
              :idPatientTherapeute="idPatientTherapeute"
              :folderBrique="getFoldersByTheme(theme.id)"
              @recupId="handleRecupId"
              :linkedObjectPatient="linkedObjectPatient"
              name="Échelles d'évaluation"
              :key="keyCarousel"
              :LinksOfUser="LinksOfUser"
            />
            <div>
              <div
                v-for="folderBrique in getFoldersByTheme(theme.id)"
                :key="folderBrique.id"
              >
                <div v-if="idProtocol == folderBrique.id">
                  <div v-if="AffichageEvaluation">
                    <div class="buttonprog">
                      <button
                        v-if="folderBrique.protocoles[0]"
                        class="buttonChoix"
                        v-on:click="show_auto()"
                        v-bind:class="{ activeRessources: isAuto }"
                      >
                        {{ folderBrique.protocoles[0].name }}
                      </button>
                      <button
                        v-if="folderBrique.protocoles[1] && user.therapeute"
                        class="buttonChoix"
                        v-on:click="show_hetero()"
                        v-bind:class="{ activeRessources: isHetero }"
                      >
                        {{ folderBrique.protocoles[1].name }}
                      </button>
                    </div>
                    <div id="listQuestionnaire" v-if="filteredModules">
                      <div
                        :class="{ isPageUser: isPageUser }"
                        v-for="(moduleP, index) in filteredModules"
                        :key="moduleP.id"
                        class="is-margin-t-10"
                        v-if="isAuto"
                      >
                        <div v-if="moduleP.name">
                          <div
                            id="boxEchelles"
                            style="
                              cursor: pointer;
                              display: flex;
                              justify-content: space-between;
                              align-items: center;
                              margin-top: 1% !important;
                              background-color: #489181 !important;
                              color: white;
                              border-radius: 15px 15px 0px 0px;
                            "
                            v-if="idModule == moduleP.id"
                            v-on:click="recupIdModule(null)"
                          >
                            <div class="test1">
                              {{ "Echelle " + moduleP.name }}
                            </div>
                            <img
                              class="arrowDown2 arrowResponsive"
                              src="../../../assets/img/playButton.png"
                              style="transform: rotate(90deg)"
                            />
                          </div>
                          <div
                            id="boxEchelles"
                            :class="{ isDone: moduleP.isDone }"
                            v-on:click="recupIdModule(moduleP.id)"
                            style="
                              margin-top: 1%;
                              cursor: pointer;
                              display: flex;
                              justify-content: space-between;
                              align-items: center;
                            "
                            v-else
                          >
                            <div class="test1">
                              {{ "Echelle " + moduleP.name }}
                            </div>
                            <img
                              class="arrowDown2 arrowResponsive"
                              src="../../../assets/img/playButton.png"
                              style=""
                            />
                          </div>

                          <div
                            id="boxExercicesEchelles"
                            v-for="(n, i) in element_link"
                            v-if="
                              element_link[i].attributes.file &&
                              idModule == moduleP.id
                            "
                          >
                            <template v-if="presentation">
                              <div v-if="resultatQCM_Object">
                                <p class="info-text">
                                  Vous avez déjà complété ce questionnaire.
                                  Voici votre dernier résultat :
                                </p>
                                <div class="data-container">
                                  <div class="data-item">
                                    <i class="fas fa-star"></i>
                                    <span
                                      >Note :
                                      {{ resultatQCM_Object.note }}</span
                                    >
                                  </div>
                                  <div class="data-item">
                                    <i class="fas fa-clock"></i>
                                    <span
                                      >Temps écoulé :
                                      {{
                                        resultatQCM_Object.time_elapsed
                                      }}s</span
                                    >
                                  </div>
                                  <div class="data-item">
                                    <i class="fas fa-calendar-alt"></i>
                                    <span
                                      >Date :
                                      {{
                                        formatDate(resultatQCM_Object.date)
                                      }}</span
                                    >
                                  </div>
                                </div>
                              </div>
                              <p
                                v-html="
                                  element_link[i].attributes.file.attributes
                                    .text
                                "
                              ></p>
                              <div style="display: flex">
                                <button
                                  style="margin-left: auto; margin-right: auto"
                                  class="buttonDemarrerQuestionnaire"
                                  v-on:click="
                                    recupIdLink(
                                      element_link[i].attributes.file.attributes
                                        .questionnaire_.id
                                    )
                                  "
                                >
                                  COMMENCER
                                </button>
                              </div>
                            </template>

                            <template v-if="question">
                              <div v-for="(n, i) in element_questionnaire">
                                <div v-for="(n, i) in element">
                                  <div
                                    class="QuestionTitleQCM"
                                    style="display: flex"
                                  >
                                    <div class="numberCircle">
                                      {{
                                        element[i].attributes.number +
                                        1 +
                                        "/" +
                                        element_questionnaire[i].attributes
                                          .number_question
                                      }}
                                    </div>
                                    <div class="QuestionQCM">
                                      {{ element[i].attributes.text }}
                                    </div>
                                  </div>
                                  <div
                                    v-for="(n, i) in element_answer"
                                    style="margin-bottom: 1rem"
                                  >
                                    <label :for="element_answer[i].id"
                                      ><input
                                        :id="element_answer[i].id"
                                        type="radio"
                                        :name="element_answer[i].id"
                                        :value="
                                          element_answer[i].attributes.points
                                        "
                                        v-model="picked"
                                      />
                                      {{
                                        "  " + element_answer[i].attributes.text
                                      }}</label
                                    >
                                  </div>
                                  <div style="display: flex">
                                    <div
                                      style="
                                        margin-left: auto;
                                        margin-right: auto;
                                      "
                                    >
                                      <button
                                        class="buttonChoix"
                                        style="
                                          color: white;
                                          background-color: #239380;
                                        "
                                        v-if="number > 0"
                                        v-on:click="PreviousQuestion(picked)"
                                      >
                                        QUESTION PRECEDENTE
                                      </button>
                                      <button
                                        class="buttonChoix"
                                        style="opacity: calc(0.5)"
                                        v-else
                                      >
                                        QUESTION PRECEDENTE
                                      </button>
                                      <button
                                        class="buttonChoix"
                                        style="
                                          color: white;
                                          background-color: #239380;
                                        "
                                        v-if="
                                          number + 1 <
                                            element_questionnaire[i].attributes
                                              .number_question &&
                                          picked != undefined
                                        "
                                        v-on:click="
                                          NextQuestion(
                                            picked,
                                            element[i].attributes.text,
                                            element[i]
                                          )
                                        "
                                      >
                                        QUESTION SUIVANTE
                                      </button>

                                      <button
                                        class="buttonChoix"
                                        style="opacity: calc(0.5)"
                                        v-if="
                                          number + 1 <
                                            element_questionnaire[i].attributes
                                              .number_question &&
                                          picked == undefined
                                        "
                                      >
                                        QUESTION SUIVANTE
                                      </button>

                                      <button
                                        style="
                                          background-color: rgb(35, 147, 128);
                                          color: white;
                                        "
                                        v-on:click="
                                          FinalResultat(
                                            element_questionnaire[i]
                                          )
                                        "
                                        class="buttonChoix"
                                        v-if="
                                          number + 1 ==
                                          element_questionnaire[i].attributes
                                            .number_question
                                        "
                                      >
                                        TERMINER
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </template>
                            <template v-if="resultatQCM">
                              <p class="ResultatScore">
                                {{ "Score de " + resultat + " points" }}
                              </p>
                              <div class="textResultat">
                                <p v-if="element_questionnaire[i]">
                                  {{
                                    "Nous vous remercions de vous être prêté(e) à notre test : " +
                                    element_questionnaire[i].attributes.name +
                                    "."
                                  }}
                                </p>
                                <p v-else>
                                  Nous vous remercions de vous être prêté(e) à
                                  notre test :
                                </p>
                                <p>
                                  {{
                                    "Vous avez obtenu " + resultat + " points."
                                  }}
                                </p>
                                <br />
                                <p>
                                  Si vous avez acceptez de partager vos
                                  pratiques avec votre professionnel référent,
                                  votre score lui a été transmis.
                                </p>
                                <button
                                  class="buttonChoix"
                                  style="
                                    background-color: rgb(35, 147, 128);
                                    color: white;
                                  "
                                  v-on:click="SaveExercice()"
                                >
                                  Sauvegarder
                                </button>
                              </div>
                            </template>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="
                        folderBrique.protocoles[0] &&
                        folderBrique.protocoles[0].modules
                      "
                      class="is-pulled-right is-size-7"
                    ></div>
                  </div>
                  <div v-if="folderBrique.protocoles[1] && AffichageEvaluation">
                    <div>
                      <div
                        :class="{ isPageUser: isPageUser }"
                        v-for="(moduleP, index) in folderBrique.protocoles[1]
                          .modules"
                        :key="moduleP.id"
                        class="is-margin-t-10"
                        v-if="isHetero && user.therapeute"
                      >
                        <div v-if="moduleP.fileIsNotEval">
                          <div
                            id="boxEchelles"
                            style="
                              margin-top: 1% !important;
                              background-color: #489181 !important;
                              color: white;
                              cursor: pointer;
                              display: flex;
                              justify-content: space-between;
                              align-items: center;
                              border-radius: 15px 15px 0px 0px;
                            "
                            v-if="idModule == moduleP.id"
                          >
                            <div class="test1">
                              {{ "Echelle " + moduleP.name }}
                            </div>
                            <img
                              class="arrowDown2 arrowResponsive"
                              src="../../../assets/img/playButton.png"
                              style="transform: rotate(90deg)"
                            />
                          </div>
                          <div
                            id="boxEchelles"
                            v-on:click="recupIdModule(moduleP.id)"
                            style="
                              margin-top: 1%;
                              cursor: pointer;
                              cursor: pointer;
                              display: flex;
                              justify-content: space-between;
                              align-items: center;
                            "
                            v-else
                          >
                            <div class="test1">
                              {{ "Echelle " + moduleP.name }}
                            </div>
                            <img
                              class="arrowDown2 arrowResponsive"
                              src="../../../assets/img/playButton.png"
                            />
                          </div>
                          <div
                            id="boxExercicesEchelles"
                            v-for="(n, i) in element_link"
                            v-if="
                              element_link[i].attributes.file &&
                              idModule == moduleP.id
                            "
                          >
                            <template v-if="presentation">
                              <div v-if="resultatQCM_Object">
                                <p class="info-text">
                                  Vous avez déjà complété ce questionnaire.
                                  Voici votre dernier résultat :
                                </p>
                                <div class="data-container">
                                  <div class="data-item">
                                    <i class="fas fa-star"></i>
                                    <span
                                      >Note :
                                      {{ resultatQCM_Object.note }}</span
                                    >
                                  </div>
                                  <div class="data-item">
                                    <i class="fas fa-clock"></i>
                                    <span
                                      >Temps écoulé :
                                      {{
                                        resultatQCM_Object.time_elapsed
                                      }}s</span
                                    >
                                  </div>
                                  <div class="data-item">
                                    <i class="fas fa-calendar-alt"></i>
                                    <span
                                      >Date :
                                      {{
                                        formatDate(resultatQCM_Object.date)
                                      }}</span
                                    >
                                  </div>
                                </div>
                              </div>
                              <p
                                v-html="
                                  element_link[i].attributes.file.attributes
                                    .text
                                "
                              ></p>

                              <div style="display: flex">
                                <button
                                  style="margin-left: auto; margin-right: auto"
                                  class="buttonDemarrerQuestionnaire"
                                  v-on:click="
                                    recupIdLink(
                                      element_link[i].attributes.file.attributes
                                        .questionnaire_.id
                                    )
                                  "
                                >
                                  COMMENCER
                                </button>
                              </div>
                            </template>
                            <template v-if="question">
                              <div v-for="(n, i) in element_questionnaire">
                                <div v-for="(n, i) in element">
                                  <div
                                    class="QuestionTitleQCM"
                                    style="display: flex"
                                  >
                                    <div class="numberCircle">
                                      {{
                                        element[i].attributes.number +
                                        1 +
                                        "/" +
                                        element_questionnaire[i].attributes
                                          .number_question
                                      }}
                                    </div>
                                    <div class="QuestionQCM">
                                      {{ element[i].attributes.text }}
                                    </div>
                                  </div>
                                  <div
                                    v-for="(n, i) in element_answer"
                                    style="margin-bottom: 1rem"
                                  >
                                    <label :for="element_answer[i].id"
                                      ><input
                                        :id="element_answer[i].id"
                                        type="radio"
                                        :name="element_answer[i].id"
                                        :value="
                                          element_answer[i].attributes.points
                                        "
                                        v-model="picked"
                                      />
                                      {{
                                        "  " + element_answer[i].attributes.text
                                      }}</label
                                    ><br />
                                  </div>
                                  <div
                                    style="
                                      margin-right: auto;
                                      margin-left: auto;
                                      width: 35%;
                                    "
                                  >
                                    <button
                                      class="buttonChoix"
                                      style="
                                        color: white;
                                        background-color: #239380;
                                      "
                                      v-if="number > 0"
                                      v-on:click="PreviousQuestion(picked)"
                                    >
                                      QUESTION PRECEDENTE
                                    </button>
                                    <button
                                      class="buttonChoix"
                                      style="opacity: calc(0.5)"
                                      v-else
                                    >
                                      QUESTION PRECEDENTE
                                    </button>
                                    <button
                                      class="buttonChoix"
                                      style="
                                        color: white;
                                        background-color: #239380;
                                      "
                                      v-if="
                                        number + 1 <
                                          element_questionnaire[i].attributes
                                            .number_question &&
                                        picked != undefined
                                      "
                                      v-on:click="
                                        NextQuestion(
                                          picked,
                                          element[i].attributes.text,
                                          element[i]
                                        )
                                      "
                                    >
                                      QUESTION SUIVANTE
                                    </button>

                                    <button
                                      class="buttonChoix"
                                      style="opacity: calc(0.5)"
                                      v-if="
                                        number + 1 <
                                          element_questionnaire[i].attributes
                                            .number_question &&
                                        picked == undefined
                                      "
                                    >
                                      QUESTION SUIVANTE
                                    </button>

                                    <button
                                      style="
                                        color: white;
                                        background-color: rgb(35, 147, 128);
                                      "
                                      v-on:click="
                                        FinalResultat(element_questionnaire[i])
                                      "
                                      class="buttonChoix"
                                      v-if="
                                        number + 1 ==
                                        element_questionnaire[i].attributes
                                          .number_question
                                      "
                                    >
                                      TERMINER
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </template>
                            <template v-if="resultatQCM">
                              <p class="ResultatScore">
                                {{ "Score de " + resultat + " points" }}
                              </p>
                              <div class="textResultat">
                                <p v-if="element_questionnaire[i]">
                                  {{
                                    "Nous vous remercions de vous être prêté(e) à notre test : " +
                                    element_questionnaire[i].attributes.name +
                                    "."
                                  }}
                                </p>
                                <p v-else>
                                  Nous vous remercions de vous être prêté(e) à
                                  notre test :
                                </p>
                                <p>
                                  {{
                                    "Vous avez obtenu " + resultat + " points."
                                  }}
                                </p>
                                <br />
                                <p>
                                  Si vous avez acceptez de partager vos
                                  pratiques avec votre professionnel référent,
                                  votre score lui a été transmis.
                                </p>
                                <button
                                  class="buttonChoix"
                                  style="
                                    background-color: rgb(35, 147, 128);
                                    color: white;
                                  "
                                  v-on:click="SaveExercice()"
                                >
                                  Sauvegarder
                                </button>
                              </div>
                            </template>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="
                        folderBrique.protocoles[1] &&
                        folderBrique.protocoles[1].modules
                      "
                      class="is-pulled-right is-size-7"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-show="isAll">
          <div v-if="theme.attributes.ordre === 7">
            <div>
              <div
                v-for="folderBrique in getFoldersByTheme(theme.id)"
                :key="folderBrique.id"
                v-if="
                  folderBrique.deverrouille && folderBrique.deverrouille_user
                "
              >
                <div v-if="folderBrique.protocoles[1] && user.therapeute">
                  <div>
                    <div
                      :class="{ isPageUser: isPageUser }"
                      v-for="(moduleP, index) in folderBrique.protocoles[1]
                        .modules"
                      :key="moduleP.id"
                      class="is-margin-t-10"
                    >
                      <div v-if="moduleP.fileIsNotEval">
                        <div>
                          <div
                            id="boxEchelles"
                            style="
                              margin-top: 1% !important;
                              background-color: #489181 !important;
                              color: white;
                              cursor: pointer;
                              display: flex;
                              justify-content: space-between;
                              align-items: center;
                              border-radius: 15px 15px 0px 0px;
                            "
                            v-if="idModule == moduleP.id"
                          >
                            <div class="test1">
                              {{ "Echelle " + moduleP.name }}
                            </div>
                            <img
                              class="arrowDown2 arrowResponsive"
                              src="../../../assets/img/playButton.png"
                              style="transform: rotate(90deg)"
                            />
                          </div>
                          <div
                            id="boxEchelles"
                            v-on:click="recupIdModule(moduleP.id)"
                            style="margin-top: 1%; cursor: pointer"
                            v-else
                          >
                            <div class="test1">
                              {{ "Echelle " + moduleP.name }}
                            </div>
                            <img
                              class="arrowDown2 arrowResponsive"
                              src="../../../assets/img/playButton.png"
                            />
                          </div>
                        </div>
                        <div
                          id="boxExercicesEchelles"
                          v-for="(n, i) in element_link"
                          v-if="
                            element_link[i].attributes.file &&
                            idModule == moduleP.id
                          "
                        >
                          <template v-if="presentation">
                            <div v-if="resultatQCM_Object">
                              <p class="info-text">
                                Vous avez déjà complété ce questionnaire. Voici
                                votre dernier résultat :
                              </p>
                              <div class="data-container">
                                <div class="data-item">
                                  <i class="fas fa-star"></i>
                                  <span
                                    >Note : {{ resultatQCM_Object.note }}</span
                                  >
                                </div>
                                <div class="data-item">
                                  <i class="fas fa-clock"></i>
                                  <span
                                    >Temps écoulé :
                                    {{ resultatQCM_Object.time_elapsed }}s</span
                                  >
                                </div>
                                <div class="data-item">
                                  <i class="fas fa-calendar-alt"></i>
                                  <span
                                    >Date :
                                    {{
                                      formatDate(resultatQCM_Object.date)
                                    }}</span
                                  >
                                </div>
                              </div>
                            </div>
                            <p
                              v-html="
                                element_link[i].attributes.file.attributes.text
                              "
                            ></p>

                            <div style="display: flex">
                              <button
                                style="margin-left: auto; margin-right: auto"
                                class="buttonDemarrerQuestionnaire"
                                v-on:click="
                                  recupIdLink(
                                    element_link[i].attributes.file.attributes
                                      .questionnaire_.id
                                  )
                                "
                              >
                                COMMENCER
                              </button>
                            </div>
                          </template>
                          <template v-if="question">
                            <div v-for="(n, i) in element_questionnaire">
                              <div v-for="(n, i) in element">
                                <div
                                  class="QuestionTitleQCM"
                                  style="display: flex"
                                >
                                  <div class="numberCircle">
                                    {{
                                      element[i].attributes.number +
                                      1 +
                                      "/" +
                                      element_questionnaire[i].attributes
                                        .number_question
                                    }}
                                  </div>
                                  <div class="QuestionQCM">
                                    {{ element[i].attributes.text }}
                                  </div>
                                </div>
                                <div
                                  v-for="(n, i) in element_answer"
                                  style="margin-bottom: 1rem"
                                >
                                  <label
                                    :for="element_answer[i].attributes.points"
                                    ><input
                                      :id="element_answer[i].attributes.points"
                                      type="radio"
                                      :name="
                                        element_answer[i].attributes.objectId
                                      "
                                      :value="
                                        element_answer[i].attributes.points
                                      "
                                      v-model="picked"
                                    />
                                    {{
                                      "  " + element_answer[i].attributes.text
                                    }}</label
                                  ><br />
                                </div>
                                <div
                                  style="
                                    margin-right: auto;
                                    margin-left: auto;
                                    width: 35%;
                                  "
                                >
                                  <button
                                    class="buttonChoix"
                                    style="
                                      color: white;
                                      background-color: #239380;
                                    "
                                    v-if="number > 0"
                                    v-on:click="PreviousQuestion(picked)"
                                  >
                                    QUESTION PRECEDENTE
                                  </button>
                                  <button
                                    class="buttonChoix"
                                    style="opacity: calc(0.5); cursor: "
                                    v-else
                                  >
                                    QUESTION PRECEDENTE
                                  </button>
                                  <button
                                    class="buttonChoix"
                                    style="
                                      color: white;
                                      background-color: #239380;
                                    "
                                    v-if="
                                      number + 1 <
                                      element_questionnaire[i].attributes
                                        .number_question
                                    "
                                    v-on:click="
                                      NextQuestion(
                                        picked,
                                        element[i].attributes.text,
                                        element[i]
                                      )
                                    "
                                  >
                                    QUESTION SUIVANTE
                                  </button>
                                  <button
                                    style="
                                      color: white;
                                      background-color: rgb(35, 147, 128);
                                    "
                                    v-on:click="
                                      FinalResultat(element_questionnaire[i])
                                    "
                                    class="buttonChoix"
                                    v-if="
                                      number + 1 ==
                                      element_questionnaire[i].attributes
                                        .number_question
                                    "
                                  >
                                    TERMINER
                                  </button>
                                </div>
                              </div>
                            </div>
                          </template>
                          <template v-if="resultatQCM">
                            <p class="ResultatScore">
                              {{ "Score de " + resultat + " points" }}
                            </p>
                            <p v-if="element_questionnaire[i].attributes.name">
                              {{
                                "Nous vous remercions de vous être prêté(e) à notre test :" +
                                element_questionnaire[i].attributes.name
                              }}
                            </p>
                            <button
                              class="buttonChoix"
                              style="
                                background-color: rgb(35, 147, 128);
                                color: white;
                              "
                              v-on:click="SaveExercice()"
                            >
                              Sauvegarder
                            </button>
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="folderBrique.protocoles[0] && folder_brique_protocole"
                >
                  <div>
                    <div
                      :class="{ isPageUser: isPageUser }"
                      v-for="(moduleP, index) in folder_brique_protocole"
                      :key="moduleP.id"
                    >
                      <div>
                        <div
                          id="boxEchelles"
                          style="
                            margin-top: 1% !important;
                            background-color: #489181 !important;
                            color: white;
                          "
                          v-if="idModule == moduleP.id"
                        >
                          <div class="test1">
                            {{ "Echelle " + moduleP.name }}
                          </div>
                          <img
                            class="arrowDown2 arrowResponsive"
                            src="../../../assets/img/playButton.png"
                            style="transform: rotate(90deg)"
                          />
                        </div>
                        <div
                          id="boxEchelles"
                          v-on:click="recupIdModule(moduleP.id)"
                          style="margin-top: 1%; cursor: pointer"
                          v-else
                        >
                          <div class="test1">
                            {{ "Echelle " + moduleP.name }}
                          </div>
                          <img
                            class="arrowDown2 arrowResponsive"
                            src="../../../assets/img/playButton.png"
                          />
                        </div>
                      </div>
                      <div
                        id="boxExercicesEchelles"
                        v-for="(n, i) in element_link"
                        v-if="
                          element_link[i].attributes.file &&
                          idModule == moduleP.id
                        "
                      >
                        <template v-if="presentation">
                          <div v-if="resultatQCM_Object">
                            <p class="info-text">
                              Vous avez déjà complété ce questionnaire. Voici
                              votre dernier résultat :
                            </p>
                            <div class="data-container">
                              <div class="data-item">
                                <i class="fas fa-star"></i>
                                <span
                                  >Note : {{ resultatQCM_Object.note }}</span
                                >
                              </div>
                              <div class="data-item">
                                <i class="fas fa-clock"></i>
                                <span
                                  >Temps écoulé :
                                  {{ resultatQCM_Object.time_elapsed }}s</span
                                >
                              </div>
                              <div class="data-item">
                                <i class="fas fa-calendar-alt"></i>
                                <span
                                  >Date :
                                  {{
                                    formatDate(resultatQCM_Object.date)
                                  }}</span
                                >
                              </div>
                            </div>
                          </div>
                          <div v-if="resultatQCM_Object">
                            <p class="info-text">
                              Vous avez déjà complété ce questionnaire. Voici
                              votre dernier résultat :
                            </p>
                            <div class="data-container">
                              <div class="data-item">
                                <i class="fas fa-star"></i>
                                <span
                                  >Note : {{ resultatQCM_Object.note }}</span
                                >
                              </div>
                              <div class="data-item">
                                <i class="fas fa-clock"></i>
                                <span
                                  >Temps écoulé :
                                  {{ resultatQCM_Object.time_elapsed }}s</span
                                >
                              </div>
                              <div class="data-item">
                                <i class="fas fa-calendar-alt"></i>
                                <span
                                  >Date :
                                  {{
                                    formatDate(resultatQCM_Object.date)
                                  }}</span
                                >
                              </div>
                            </div>
                          </div>
                          <div v-if="resultatQCM_Object">
                            <p class="info-text">
                              Vous avez déjà complété ce questionnaire. Voici
                              votre dernier résultat :
                            </p>
                            <div class="data-container">
                              <div class="data-item">
                                <i class="fas fa-star"></i>
                                <span
                                  >Note : {{ resultatQCM_Object.note }}</span
                                >
                              </div>
                              <div class="data-item">
                                <i class="fas fa-clock"></i>
                                <span
                                  >Temps écoulé :
                                  {{ resultatQCM_Object.time_elapsed }}s</span
                                >
                              </div>
                              <div class="data-item">
                                <i class="fas fa-calendar-alt"></i>
                                <span
                                  >Date :
                                  {{
                                    formatDate(resultatQCM_Object.date)
                                  }}</span
                                >
                              </div>
                            </div>
                          </div>
                          <p
                            v-html="
                              element_link[i].attributes.file.attributes.text
                            "
                          ></p>

                          <div style="display: flex">
                            <button
                              style="margin-left: auto; margin-right: auto"
                              class="buttonDemarrerQuestionnaire"
                              v-on:click="
                                recupIdLink(
                                  element_link[i].attributes.file.attributes
                                    .questionnaire_.id
                                )
                              "
                            >
                              COMMENCER
                            </button>
                          </div>
                        </template>
                        <template v-if="question">
                          <div v-for="(n, i) in element_questionnaire">
                            <div v-for="(n, i) in element">
                              <div
                                class="QuestionTitleQCM"
                                style="display: flex"
                              >
                                <div class="numberCircle">
                                  {{
                                    element[i].attributes.number +
                                    1 +
                                    "/" +
                                    element_questionnaire[i].attributes
                                      .number_question
                                  }}
                                </div>
                                <div class="QuestionQCM">
                                  {{ element[i].attributes.text }}
                                </div>
                              </div>
                              <div
                                v-for="(n, i) in element_answer"
                                style="margin-bottom: 1rem"
                              >
                                <label
                                  :for="element_answer[i].attributes.points"
                                  ><input
                                    :id="element_answer[i].attributes.points"
                                    type="radio"
                                    :name="
                                      element_answer[i].attributes.objectId
                                    "
                                    :value="element_answer[i].attributes.points"
                                    v-model="picked"
                                  />
                                  {{
                                    "  " + element_answer[i].attributes.text
                                  }}</label
                                ><br />
                              </div>
                              <div
                                style="
                                  margin-right: auto;
                                  margin-left: auto;
                                  width: 35%;
                                "
                              >
                                <button
                                  class="buttonChoix"
                                  style="
                                    color: white;
                                    background-color: #239380;
                                  "
                                  v-if="number > 0"
                                  v-on:click="PreviousQuestion(picked)"
                                >
                                  QUESTION PRECEDENTE
                                </button>
                                <button class="buttonChoix" v-else>
                                  QUESTION PRECEDENTE
                                </button>
                                <button
                                  class="buttonChoix"
                                  style="
                                    color: white;
                                    background-color: #239380;
                                  "
                                  v-if="
                                    number + 1 <
                                    element_questionnaire[i].attributes
                                      .number_question
                                  "
                                  v-on:click="
                                    NextQuestion(
                                      picked,
                                      element[i].attributes.text,
                                      element[i]
                                    )
                                  "
                                >
                                  QUESTION SUIVANTE
                                </button>
                                <button
                                  style="
                                    color: white;
                                    background-color: rgb(35, 147, 128);
                                  "
                                  v-on:click="
                                    FinalResultat(element_questionnaire[i])
                                  "
                                  class="buttonChoix"
                                  v-if="
                                    number + 1 ==
                                    element_questionnaire[i].attributes
                                      .number_question
                                  "
                                >
                                  TERMINER
                                </button>
                                <button
                                  class="buttonChoix"
                                  style="
                                    background-color: rgb(35, 147, 128);
                                    color: white;
                                  "
                                  v-on:click="SaveExercice()"
                                  v-if="resultatQCM"
                                >
                                  Sauvegarder
                                </button>
                              </div>
                            </div>
                          </div>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import CarouselFolders from "@/components/accueilV2/CarouselFolders.vue";
import Parse from "parse";
import ParseConfig from "@/api/parse.config";
import { createNamespacedHelpers } from "vuex";
const User = createNamespacedHelpers("user");
import { getFilesDoneByUserId } from "@/services/folders/getFilesDoneByUserId.js";
import { getFilesDonesID } from "@/services/folders/getFilesDonesID";
import bfpComponent from "./bfp/bfpComponent.vue";
import { getInfoForNotif } from "@/services/notifications/getInfoForNotif.js";
import { createNotificationForHighScoreCritical } from "@/services/notifications/createNotificationForHighScoreCritical.js";
import { getResultatFromQCM } from "@/services/folders/getResultatFromQCM.js";
export default {
  name: "folders",
  props: [
    "foldersBrique",
    "isPageUser",
    "isPageSessionGroup",
    "themes",
    "idPatientTherapeute",
    "LinksOfUser",
  ],
  watch: {
    LinksOfUser: {
      handler(newValue) {
        // Exécuter du code lorsque LinksOfUser change
        console.log("LinksOfUser a changé :", newValue);
        this.keyCarousel++;
        this.handleRecupId(null);

        // Vous pouvez effectuer d'autres actions ici, comme mettre à jour des données locales
      },
      immediate: true, // Si vous souhaitez exécuter le handler au montage du composant avec la valeur initiale
      deep: true, // Utilisez 'deep: true' si vous voulez écouter les changements dans les objets à l'intérieur du tableau
    },
  },
  data() {
    return {
      openTherapiesNonMedicamenteuses: true,
      keyCarousel: 200,
      openAccompagnementPsychophysiologique: true,
      openEvaluationsPsychotechniques: true,
      descriptionSelected: "",
      idProtocol: "",
      AffichageTheme: true,
      AffichageEvaluation: true,
      isDown: false,
      isAuto: true,
      isHetero: false,
      isTheme: true,
      isAll: false,
      element: [],
      tableau: [],
      element_questionnaire: [],
      tableau_questionnaire: [],
      element_file: [],
      tableau_file: [],
      element_link: [],
      bfp_acces: false,
      tableau_link: [],
      element_answer: [],
      tableau_answer: [],
      idModule: "",
      idQuestionnaire: "",
      idQuestion: "",
      questionAffichage: false,
      number: 0,
      question: "",
      IdLink: "",
      presentation: true,
      descriptionPro: null,
      question: false,
      resultat: 0,
      picked: 0,
      resultatQCM: false,
      checked: false,
      linkedObjectPatient: [],
      element_user_local: [],
      showNotification: false,
      idOfFileDones: [],
      filesDonesId: [],
      isLoading: false,
      elapsedTime: 0,
      answers: [],
      resultatQCM_Object: [],
      abonnementFinish: false,
      idModuleLock: [],
      folder_brique_protocole: [],
      categoryScores: {},
      isTherapeute: false,
    };
  },
  async created() {
    if (Parse.User.current().attributes.therapeute) {
      this.isTherapeute = true;
    }
    if (Parse.User.current().attributes.bfp_acces) {
      this.bfp_acces = true;
    } else {
      this.bfp_acces = false;
    }
    this.idUserForSave = this.getCookie("idUserForSave");

    if (this.idUserForSave) {
      this.filesDonesId = await getFilesDonesID(this.idUserForSave);
    } else if (this.idPatientTherapeute) {
      this.filesDonesId = await getFilesDonesID(this.idPatientTherapeute);
    } else {
      this.filesDonesId = await getFilesDonesID(this.user.objectId);
    }
  },
  computed: {
    ...User.mapGetters(["user", "group"]),
    filteredModules() {
      if (this.isTherapeute) {
        // Si isTherapeute est true, renvoyer le tableau non filtré
        return this.folder_brique_protocole;
      } else {
        // Sinon, appliquer le filtre
        return this.folder_brique_protocole.filter(
          (moduleP) => !this.idModuleLock.includes(moduleP.id)
        );
      }
    },
  },
  components: {
    bfpComponent,
    CarouselFolders,
  },
  methods: {
    async handleTerminerExerciceQCM(id, resultat, answers, categoryScores) {
      this.answers = answers;
      this.categoryScores = categoryScores;
      var QueryFile = new Parse.Query("file");
      console.log("Voici le id du file: ", id);
      QueryFile.equalTo("objectId", id);
      QueryFile.include("questionnaire_");
      var File = await QueryFile.first();
      // var QueryLink = new Parse.Query("link");
      // QueryLink.matchesQuery("questionnaire_", QueryFile);
      // var Link = QueryLink.first();
      // var IdLink = Link.id;
      // this.IdLink = IdLink;
      console.log("Voici le file:", File);
      this.TerminerExercice(id, resultat);
    },
    timerExercice() {
      this.exerciseStartTime = Date.now();
      setInterval(() => {
        this.elapsedTime = Math.floor(
          (Date.now() - this.exerciseStartTime) / 1000
        );
      }, 1000);
    },
    verifAbonnement() {
      var user = Parse.User.current();
      //console.log("User récupére: ", user);
      var userTypeAbonnement = user.get("group").get("type_abonnement");
      var userFinAbonnement = user.get("group").get("subscription_end_date");
      //console.log("userTypeAbonnement: ", userTypeAbonnement);
      //console.log("userFinAbonnement: ", userFinAbonnement);
      var today = new Date();
      if (userTypeAbonnement === "gratuit" && userFinAbonnement < today) {
        this.abonnementFinish = true;
      } else {
        this.abonnementFinish = false;
      }
    },
    getCookie(name) {
      var value = "; " + document.cookie;
      var parts = value.split("; " + name + "=");
      if (parts.length == 2) return parts.pop().split(";").shift();
    },
    formatDate(dateString) {
      const options = { year: "numeric", month: "long", day: "numeric" };
      return new Date(dateString).toLocaleDateString("fr-FR", options);
    },
    affichageAccesPerso() {
      const objectUser = Parse.Object.extend("User");
      const queryUser = new Parse.Query(objectUser);
      queryUser.equalTo("objectId", this.idPatientTherapeute);
      queryUser.include("link");
      queryUser.find().then((e) => {
        this.element_user_local = e;
        if (
          this.element_user_local[0] &&
          this.element_user_local[0].get("link")
        ) {
          const relation = this.element_user_local[0].get("link");
          const relationQuery = relation.query();
          relationQuery.doesNotExist("parent_folder");
          relationQuery.find().then((linkedObjects) => {
            linkedObjects.forEach((linkedObject) => {
              this.linkedObjectPatient.push(linkedObject.attributes.folder.id);
            });
          });
        }
      });
    },
    scrollToSection() {
      setTimeout(() => {
        const section = document.querySelector("#listQuestionnaire");
        section.scrollIntoView({ behavior: "smooth" });
      }, 200);
    },
    handleRecupId(folderbrique) {
      this.recupId(folderbrique);
    },
    openLoading() {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
      }, 1000);
    },
    getFoldersByTheme(monTheme) {
      return this.foldersBrique.filter((el) => {
        if (el.themes.length < 2) {
          return el.themes[0].objectId === monTheme;
        }
        return (
          el.themes[0].objectId === monTheme ||
          el.themes[1].objectId === monTheme
        );
      });
    },
    startTimer() {
      this.showNotification = true;
      this.progressWidth = 0;
      if (this.intervalId) clearInterval(this.intervalId);
      this.intervalId = setInterval(() => {
        this.progressWidth += 3.33;
        if (this.progressWidth >= 100) {
          clearInterval(this.intervalId);
        }
      }, 100);
      setTimeout(() => {
        this.closeNotification();
      }, 3000);
    },
    closeNotification() {
      this.showNotification = false;
      this.progressWidth = 0;
    },
    FinalResultat(idReçu) {
      this.resultatQCM = true;
      this.question = false;
      // this.TerminerExercice(this.idModuleSelected, this.resultat);
    },
    SaveExercice() {
      this.TerminerExercice(this.idModuleSelected, this.resultat);
    },
    OpenPopUpContact() {
      this.$emit("pop-up-contact", SeanceSelectedObject);
    },
    async TerminerExercice(fileId, note) {
      var noteQuestionnaire = note.toString();
      // this.answers = JSON.stringify(this.answers);
      try {
        console.log("answers: ", this.answers);
        Parse.Cloud.run(
          "saveExo",
          {
            idProtocol: this.idProtocol,
            SeanceSelected: this.idModule,
            fileId: this.IdLink,
            IdLink: this.IdLink,
            note: parseInt(noteQuestionnaire),
            idPatientTherapeute: this.idPatientTherapeute,
            idUserForSave: this.idUserForSave
              ? this.idUserForSave
              : this.idPatientTherapeute,
            elapsedTime: this.elapsedTime,
            answers: this.answers,
            note_detail: this.categoryScores,
            typeExercice: "Questionnaire",
          },
          {
            success: function (result) {
              //console.log("result: ", result);
            },
            error: function (error) {
              //console.log("error: ", error);
            },
          }
        );
        this.categoryScores = {};
        this.answers = [];
        this.showNotification = true;
        this.startTimer();
        setTimeout(() => {
          this.recupIdModule(null);
          this.$emit("pop-up-contact", this.showNotification);
        }, 2000);
      } catch (e) {
        console.log(e);
      }
    },
    NextQuestion(picked, texteReponse, question) {
      this.answers.push({
        question_id: question.id,
        question: texteReponse,
        note: picked,
      });
      this.resultat += picked;
      if (question.attributes.category) {
        const categoryName = question.attributes.category.attributes.name;
        // Si un score cumulatif n'existe pas pour cette catégorie, le créer et l'initialiser à 0
        if (!this.categoryScores[categoryName]) {
          this.categoryScores[categoryName] = 0;
        }
        // Ajouter le score de la question au résultat cumulatif de la catégorie correspondante
        this.categoryScores[categoryName] += picked;
      }
      return this.number++, this.callData();
    },
    PreviousQuestion(picked) {
      this.resultat -= picked;
      return this.number--, this.callData();
    },
    async verifModule(id) {
      try {
        //console.log("recupIdExerciceLock",id);
        var folder = Parse.Object.extend("folder");
        var query = new Parse.Query(folder);
        query.equalTo("objectId", id);
        var folderBrique = await query.first();
        //console.log("folderBrique: ", folderBrique);
        var user = Parse.User.current();
        var relation = user.relation("link");
        var query = relation.query();
        query.equalTo("parent_folder", folderBrique);

        query.find().then((e) => {
          e.forEach((element) => {
            //console.log("element: ", element);
            this.idModuleLock.push(element.attributes.folder.id);
          });
          //console.log("this.idModuleLock: ", this.idModuleLock);
        });
      } catch (e) {
        //console.log(e);
      }
    },
    recupId(folderBrique) {
      if (!folderBrique) {
        this.isAuto = false;
        this.isHetero = false;
        this.idProtocol = null;
        this.folder_brique_protocole = [];
        this.folder_brique_protocole2 = [];
        return;
      }
      this.verifModule(folderBrique.protocoles[0].id);
      this.isAuto = true;
      this.isHetero = false;
      this.idProtocol = folderBrique.id;
      this.folder_brique_protocole = folderBrique.protocoles[0].modules;
      this.folder_brique_protocole2 = folderBrique.protocoles[1].modules;
      for (let i = 0; i < this.folder_brique_protocole.length; i++) {
        if (this.filesDonesId.includes(this.folder_brique_protocole[i].id)) {
          this.folder_brique_protocole[i].isDone = true;
        }
      }
      for (let i = 0; i < this.folder_brique_protocole2.length; i++) {
        if (this.filesDonesId.includes(this.folder_brique_protocole2[i].id)) {
          this.folder_brique_protocole2[i].isDone = true;
        }
      }
      // this.scrollToSection();
    },
    async recupIdModule(idModuleSelected) {
      //console.log("idModuleSelected: ", idModuleSelected);
      this.answers = [];
      this.number = 0;
      this.resultat = 0;
      this.presentation = true;
      this.question = false;
      this.resultatQCM = false;
      this.idModule = idModuleSelected;
      this.callData();
      // vérifie si le idModuleSelected est dans le tableau des id file done
      if (this.filesDonesId.includes(idModuleSelected)) {
        if (this.idPatientTherapeute) {
          this.resultatQCM_Object = await getResultatFromQCM(
            idModuleSelected,
            this.idPatientTherapeute
          );
        } else if (this.idUserForSave) {
          this.resultatQCM_Object = await getResultatFromQCM(
            idModuleSelected,
            this.idUserForSave
          );
        } else {
          this.resultatQCM_Object = await getResultatFromQCM(
            idModuleSelected,
            this.user.objectId
          );
        }
      } else {
        this.resultatQCM_Object = null;
      }
    },
    recupIdQuestionnaire(idQuestionnaireSelected) {
      this.questionAffichage = true;
      this.number = 0;
      this.idQuestionnaire = idQuestionnaireSelected;
    },
    recupIdQuestion(idQuestionSelected) {
      return (this.idQuestion = idQuestionSelected);
    },
    recupIdLink(IdLinkSelected) {
      this.answers = [];
      this.presentation = false;
      this.question = true;
      this.timerExercice();
      this.IdLink = IdLinkSelected;
      this.number = 0;
      this.callData();
    },
    show_auto() {
      this.isAuto = true;
      this.isHetero = false;
    },
    show_hetero() {
      this.isAuto = false;
      this.isHetero = true;
    },
    show_theme() {
      this.isTheme = true;
      this.isAll = false;
    },
    show_all() {
      this.isAll = true;
      this.isTheme = false;
    },
    callData() {
      this.checked = false;
      this.picked = null;
      ParseConfig();
      const ObjectFolder = Parse.Object.extend("folder");
      const ObjectLink = Parse.Object.extend("link");
      const ObjectFile = Parse.Object.extend("file");
      const ObjectQuestionnaire = Parse.Object.extend("questionnaire");
      const ObjectQuestion = Parse.Object.extend("question");
      const ObjectAnswer = Parse.Object.extend("question_answer");

      const QueryFolder = new Parse.Query(ObjectFolder);
      const QueryLink = new Parse.Query(ObjectLink);
      const QueryFile = new Parse.Query(ObjectFile);
      const QueryQuestionnaire = new Parse.Query(ObjectQuestionnaire);
      const QueryQuestion = new Parse.Query(ObjectQuestion);
      const QueryAnswer = new Parse.Query(ObjectAnswer);

      try {
        QueryFolder.equalTo("objectId", this.idModule);
        QueryLink.matchesQuery("parent_folder", QueryFolder);
        QueryQuestionnaire.equalTo("objectId", this.IdLink);
        QueryQuestion.equalTo("number", this.number);
        QueryQuestion.matchesQuery("questionnaire", QueryQuestionnaire);
        QueryAnswer.matchesQuery("question", QueryQuestion);

        QueryFolder.find().then((e) => {
          return (this.element_folder = e);
        });
        QueryLink.find().then((e) => {
          return (this.element_link = e);
        });
        QueryFile.limit(1000)
          .find()
          .then((e) => {
            return (this.element_file = e);
          });
        QueryQuestionnaire.find().then((e) => {
          return (this.element_questionnaire = e);
        });
        QueryAnswer.find().then((e) => {
          return (this.element_answer = e);
        });
        QueryQuestion.find().then((e) => {
          return (this.element = e);
        });

        tableau_folder.push(element_folder);
        tableau_link.push(element_link);
        tableau_file.push(element_file);
        tableau_questionnaire.push(element_questionnaire);
        tableau.push(element);
        tableau_answer.push(element_answer);

        this.$forceUpdate();
      } catch {
        ("ERROR");
      }
    },
  },
  async mounted() {
    this.descriptionPro = this.group.description;
    await this.callData();
  },
};
</script>

<style>
.buttonprog {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 1%;
}

.buttonDemarrer {
  float: right;
  text-decoration: none;
  text-transform: none;
  padding: 10px;
  min-height: 40px;
  border-radius: 20px;
  background-color: lightgray;
  border: 0 none;
  outline: 0 none;
  -webkit-appearance: button;
  cursor: pointer;
}

.buttonChoix {
  text-decoration: none;
  text-transform: none;
  padding: 10px;
  min-height: 40px;
  border-radius: 20px;
  background-color: lightgray;
  border: 0 none;
  outline: 0 none;
  -webkit-appearance: button;
  cursor: pointer;
  margin: 8px;
  font-size: 80%;
}
@media (max-width: 768px) {
  .responsiveAnswer {
    margin-bottom: 1rem;
  }
}
.arrowDown_echelles {
  float: right;
  width: 40px;
  position: relative;
}
.questionnaireForm {
  font-size: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.buttonDemarrerQuestionnaire {
  text-decoration: none;
  text-transform: none;
  padding: 10px;
  min-height: 40px;
  border-radius: 20px;
  background-color: rgb(35, 147, 128);
  border: 0 none;
  outline: 0 none;
  -webkit-appearance: button;
  cursor: pointer;
  margin: 1%;
  color: white;
}
.isDone {
  background-color: rgb(35, 147, 128) !important;
  color: white !important;
}
#boxEchelles {
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: Roboto, sans-serif;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 15px;
  -webkit-box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%),
    0 0px 0 1px rgb(10 10 10 / 2%);
  box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%),
    0 0px 0 1px rgb(10 10 10 / 2%);
  color: #4a4a4a;
  display: block;
  border: 1px solid #00800069;
}
.data-container {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  background-color: #f4f4f4;
  border-radius: 5px;
  margin: 10px 0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.data-item {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #333;
}

.data-item i {
  margin-right: 10px;
  color: rgb(35, 147, 128);
  font-size: 20px;
}

.info-text {
  font-size: 16px;
  color: #666;
  margin-bottom: 10px;
  font-style: italic;
}

.button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.buttonDemarrerQuestionnaire {
  padding: 10px 20px;
  background-color: rgb(35, 147, 128);
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.buttonDemarrerQuestionnaire:hover {
  background-color: rgb(35, 147, 128);
}
@media screen and (max-width: 768px) {
  #boxEchelles {
    padding: 1rem;
  }
}
@media screen and (min-width: 768px) {
  #boxEchelles {
    padding: 1.5rem;
  }
}
@media screen and (max-width: 768px) {
  #boxEchelles {
    width: 100%;
  }
}

#boxEchelles:hover {
  border: 1px solid green;
  background-color: #239380 !important;
  color: white;
}

#boxExercicesEchelles {
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: Roboto, sans-serif;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 0% 0% 10px 10px;
  -webkit-box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%),
    0 0px 0 1px rgb(10 10 10 / 2%);
  box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%),
    0 0px 0 1px rgb(10 10 10 / 2%);
  color: #4a4a4a;
  display: block;
  padding: 1.5rem;
  border: 1px solid #00800069;
}
@media screen and (max-width: 768px) {
  #boxExercicesEchelles {
    width: 100%;
  }
}
.activeRessources {
  background-color: rgb(35, 147, 128);
  color: white;
}
.QuestionTitleQCM {
  display: flex;
  align-items: center;
  font-size: 1.2em;
  margin-bottom: 1rem;
}

.numberCircle {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 3em;
  height: 3em;
  background-color: #f0f0f0;
  color: #333;
  font-weight: bold;
  margin-right: 1rem;
  order: 2;
}

.QuestionQCM {
  flex-grow: 1;
  font-weight: bold;
  order: 1;
}

/* Media query for mobile screens */
@media (max-width: 767px) {
  .numberCircle {
    order: 1;
    margin-bottom: 1rem;
  }
  .QuestionQCM {
    order: 2;
  }
}

.briqueHover:hover {
  border: solid 2px #489181;
  border-radius: 10px;
}

.Question {
  font-size: larger;
}

.ResultatScore {
  color: rgb(72, 145, 129) !important;
  font-size: x-large;
  margin-left: auto;
  margin-right: auto;
  width: 25%;
}
input[type="radio"] {
  accent-color: rgb(72, 145, 129) !important;
  -ms-transform: scale(1.5); /* IE 9 */
  -webkit-transform: scale(1.5); /* Chrome, Safari, Opera */
  transform: scale(1.5);
  margin: 1%;
  margin-left: 8%;
}
.numberCircle {
  line-height: 50px;
  border-radius: 50%;
  text-align: center;
  font-size: 18px;
  border: 2px solid #489181;
  margin-left: auto;
  margin-right: auto;
}
.QuestionQCM {
  font-size: large;
  margin-bottom: 2%;
  padding-top: 1.5%;
  padding-left: 1%;
  margin-left: 6% !important;
}
.QuestionTitleQCM {
  width: 100%;
}
.textResultat {
  font-size: medium;
  text-align: center;
}

.arrowDown2 {
  float: right;
  width: 3%;
}
.descriptionPro {
  margin: 0px 19% 0px 19% !important;
  box-shadow: 1px 1px 2px 0 rgb(0 0 0 / 50%);
  background-color: rgba(236, 231, 231, 0.5);
  padding: 15px;
}
.arrowResponsive {
  transform: rotate(90deg);
  margin-right: 1%;
  width: 2rem;
}
</style>
