<template>
  <div class="user-menu">
    <div class="menu-header">
      <h2 class="menu-title">Utilisateurs</h2>
      <SearchBar :users="users" @select-user="selectUser" />
    </div>
    <div class="menu-content">
      <ul class="user-list">
        <li 
          class="user-item activity-space" 
          @click="selectUser('activitySpace')"
          :class="{ active: selectedUser === 'activitySpace' && $IdPatient === null }"
        >
          <font-awesome-icon icon="home" class="user-icon" /> 
          <span class="user-text">Mon espace d'activité</span>
        </li>
        <li
          v-for="user in users"
          :key="user.id"
          class="user-item"
          @click="selectUser(user)"
          :class="{ active: selectedUser?.id === user.id || $IdPatient === user.id }"
        >
          <font-awesome-icon icon="user" class="user-icon" />
          <span class="user-text">{{ user.get("firstname") + " " + user.get("lastname") }}</span>
        </li>
      </ul>
    </div>
    <LoaderComponent v-if="isLoading" />
    <div v-if="error" class="error-message">{{ error }}</div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import Parse from "parse";
import LoaderComponent from "./loaderComponent.vue";
import SearchBar from "./SearchBar.vue";
import {setIdPatientGlobal,resetIdPatientGlobal} from "@/services/utilisateurs/globalSettingsUser.js";

export default {
  components: {
    LoaderComponent,
    SearchBar,
  },
  watch: {
    $IdPatient: {
      handler: function (newValue) {
        console.log("IdPatient changed", newValue);
        if (newValue === null) {
          this.selectUser('activitySpace');
        }
        
      },
      immediate: true,
    },
  },
  setup(_, { emit }) {
    const users = ref([]);
    const selectedUser = ref("activitySpace");
    const isLoading = ref(false);
    const error = ref(null);
    const links = ref([]);
    const isLoadingActivitySpace = ref(false);

    const selectUser = async (user) => {
      emit("isLoadingActivitySpace", true);

      if (user === 'activitySpace') {
        console.log("%cEntrée dans la méthode ActivitySpace", "color: blue;");
        selectedUser.value = 'activitySpace';
        links.value = [];
        document.cookie = "idUserForSave=; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC;";
        emit('ReloadHeader', true);
        emit("IdUser", "");
        emit("linkUser", links.value);
        emit("isLoadingActivitySpace", false);
        resetIdPatientGlobal();
        console.log(user);
        return;
      }
      
      selectedUser.value = user;
      try {
        const linksResponse = await Parse.Cloud.run("getUserLinks", {
          objectId: user.id,
        });
        links.value = linksResponse || [];
        emit("linkUser", links.value);
        emit("isLoadingActivitySpace", false);
        emit("IdUser", user.id);
        setIdPatientGlobal(user.id);
        emit("UndisplayHeader", true);
        console.log("Liens trouvés:", links.value);
      } catch (err) {
        error.value = "Erreur lors de la récupération des liens.";
        console.error("Erreur lors de la récupération des liens:", err.message);
      }
    };

    const fetchUsersByGroupId = async (groupId) => {
      isLoading.value = true;
      error.value = null;
      try {
        const response = await Parse.Cloud.run("getUsersByGroupId", { groupId });
        users.value = response || [];
        console.log("Utilisateurs trouvés:", users.value);
      } catch (err) {
        error.value = "Erreur lors de la récupération des utilisateurs.";
        console.error("Erreur lors de la récupération des utilisateurs:", err.message);
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(() => {
      if (selectedUser.value === 'activitySpace') {
        document.cookie = "idUserForSave=; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC;";
      }
      const groupId = Parse.User.current()?.get("group")?.id;
      if (groupId) {
        fetchUsersByGroupId(groupId);
      } else {
        error.value = "Aucun groupe associé à cet utilisateur.";
      }
    });

    return {
      users,
      selectedUser,
      isLoading,
      error,
      selectUser,
      links,
      isLoadingActivitySpace,
    };
  },
};
</script>
<style scoped>
.user-menu {
  width: 100%;
  max-width: 280px;
  background-color: #ffffff;
  border-right: 1px solid #e0e0e0;
  display: flex;
  flex-direction: column;
  height: 100%;
  transition: all 0.3s ease;
  box-shadow: 2px 0 10px rgba(0, 0, 0, 0.1);
}

.menu-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-right: 15px;
  padding-left: 15px;
}

.menu-title {
  font-size: 1.4rem;
  color: #333;
  font-weight: 600;
  margin: 0 0 15px 0;
}

.menu-content {
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px;
}

.user-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.user-item {
  padding: 12px 16px;
  margin: 8px 0;
  cursor: pointer;
  border-radius: 8px;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  font-size: 0.95rem;
  color: #555;
  font-weight: 500;
  background-color: #f8f9fa;
}

.user-item:hover {
  background-color: #e9ecef;
  transform: translateX(5px);
}

.user-item.active {
  background-color: #239380;
  color: white;
  box-shadow: 0 2px 10px rgba(35, 147, 128, 0.3);
}

.user-item.active:hover {
  background-color: #1a7d6c;
}

.user-icon {
  margin-right: 12px;
  font-size: 1.1rem;
  color: #239380;
  transition: color 0.3s;
}

.user-item.active .user-icon {
  color: white;
}

.activity-space {
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 15px;
  padding-bottom: 15px;
}

.error-message {
  text-align: center;
  color: #ff4d4f;
  font-size: 0.9rem;
  padding: 12px;
  background-color: #fff2f0;
  border: 1px solid #ffccc7;
  border-radius: 6px;
  margin: 15px;
}

/* Styles pour le loader */
:deep(.loader) {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

/* Media queries pour la responsivité */
@media (max-width: 768px) {
  .user-menu {
    max-width: 100%;
    border-right: none;
    border-bottom: 1px solid #e0e0e0;
  }

  .menu-header {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .menu-title {
    margin: 0;
  }

  .user-item {
    padding: 10px 14px;
  }

  .user-text {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .menu-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .menu-title {
    margin-bottom: 10px;
  }

  .user-item {
    padding: 8px 12px;
  }

  .user-icon {
    font-size: 1rem;
  }

  .user-text {
    font-size: 0.85rem;
  }
}
.user-text {
  display: inline-block;
  max-width: 200px; /* Ajuste cette valeur selon ton design */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
}
</style>